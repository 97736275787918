import cx from "classnames";
import { ReactNode } from "react";

import { NavLink } from "react-router-dom";
import { ButtonProps } from "../Button";
import { ButtonGroup } from "../ButtonGroup";
import { SearchBar } from "../SearchBar";

interface HeaderTabs {
  label?: string;
  active?: boolean;
  url: string;
}
interface TableHeaderTypes {
  buttons?: ButtonProps[];
  children?: ReactNode;
  onSearch?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  headerText?: string | ReactNode;
  headerTabs?: HeaderTabs[];
  searchPlaceholder?: string;
  className?: string;
}

export const TableHeader = ({
  children,
  buttons,
  onSearch,
  headerText,
  headerTabs,
  searchPlaceholder,
  className,
}: TableHeaderTypes) => {
  const styles = cx("flex justify-between", !headerTabs && "pb-6", className);
  return (
    <div className={styles}>
      {headerTabs ? (
        <div className="flex text-3xl space-x-6">
          {headerTabs.map((i, index) => {
            return (
              <NavLink
                to={i.url}
                className={cx(
                  "py-8 flex items-center leading-none text-gray-400 cursor-pointer border-transparent border-b-4 font-light hover:text-blue-400",
                  i.active && "border-b-blue-400 !text-black font-medium",
                )}
                key={index}
              >
                {i.label}
              </NavLink>
            );
          })}
        </div>
      ) : (
        <div className="text-3xl">{headerText}</div>
      )}

      <div className="flex space-x-4 items-center">
        {children}

        {searchPlaceholder && onSearch && (
          <SearchBar
            size="sm"
            placeholder={searchPlaceholder}
            onChange={onSearch}
          />
        )}
        {buttons && <ButtonGroup buttons={buttons} />}
      </div>
    </div>
  );
};
