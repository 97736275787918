import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { listFeatures } from "@data/features";
import { errorMessage } from "@data/index";
import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import {
  CompanyResponseData,
  CreateCompanyOverrideRequestBody,
  CreateOrUpdateConditionRequestBodyMetricPeriodEnum,
  EntityTraitDefinitionResponseData,
} from "@models/api";
import { CompanyOverride, EntitlementValueType } from "@models/entitlement";
import { Feature, FeatureType } from "@models/feature";
import { listCompanies } from "@modules/companies/queries";
import { OverrideExpirationBlock } from "@modules/features/components/overlays/CompanyOverrideOverlay/OverrideExpirationBlock";
import { OverrideLimitsBlock } from "@modules/features/components/overlays/CompanyOverrideOverlay/OverrideLimitsBlock";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { DynamicTitle } from "@ui/DynamicTitle";
import { FormColumn, FormHeader } from "@ui/FormParts";
import {
  Overlay,
  OverlayFooter,
  OverlayHeaderClose,
  OverlayModal,
} from "@ui/Overlay";

import { Separator } from "@ui/Separator";
import { toUTCDate } from "@utils/date";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import {
  createCompanyOverride,
  updateCompanyOverride,
} from "../../../queries/companyOverrides";

export type CreateCompanyOverrideFormValues =
  CreateCompanyOverrideRequestBody & {
    company?: CompanyResponseData;
    feature?: Feature;
    trait?: EntityTraitDefinitionResponseData;
    expirationEnabled?: boolean;
  };

export interface CompanyOverrideOverlayProps {
  company?: CompanyResponseData;
  feature?: Feature;
  override?: CompanyOverride;
  onClose: () => void;
}

export const CompanyOverrideOverlay = ({
  company,
  override,
  feature,
  onClose,
}: CompanyOverrideOverlayProps) => {
  feature ||= override?.feature;
  company ||= override?.company;

  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string | undefined>();

  const timedOverrideFlag = useSchematicFlag("timed-override", {
    fallback: false,
  });

  const defaultValueType =
    feature?.featureType === FeatureType.Boolean
      ? EntitlementValueType.Boolean
      : EntitlementValueType.Numeric;

  const initialValues: CreateCompanyOverrideFormValues = {
    company: override?.company ?? company,
    companyId: override?.companyId ?? company?.id ?? "",
    feature: override?.feature ?? feature,
    featureId: override?.featureId ?? feature?.id ?? "",
    metricPeriod: override?.metricPeriod ?? undefined,
    metricPeriodMonthReset: override?.metricPeriodMonthReset ?? undefined,
    trait: override?.valueTrait,
    valueBool: override?.valueBool ?? true,
    valueNumeric: override?.valueNumeric ?? 0,
    valueTraitId: override?.valueTraitId,
    valueType: override?.valueType ?? defaultValueType,
    expirationEnabled: !!override?.expirationDate,
    expirationDate: override?.expirationDate
      ? new Date(new Date(override.expirationDate).setHours(23, 59, 59, 999))
      : undefined,
  };

  const validationSchema = Yup.object().shape({
    companyId: Yup.string().required("Required"),
    featureId: Yup.string().required("Required"),
    metricPeriod: Yup.string().when(["feature", "valueType"], {
      is: (feature: Feature, valueType: EntitlementValueType) =>
        feature?.featureType == FeatureType.Event &&
        valueType !== EntitlementValueType.Unlimited,
      then: () => Yup.string().required("Required"),
    }),
    metricPeriodMonthReset: Yup.string().when("metricPeriod", {
      is: (metricPeriod: CreateOrUpdateConditionRequestBodyMetricPeriodEnum) =>
        metricPeriod ===
        CreateOrUpdateConditionRequestBodyMetricPeriodEnum.CurrentMonth,
      then: () => Yup.string().required("Required"),
    }),
    valueNumeric: Yup.string().when("valueType", {
      is: (valueType: EntitlementValueType) =>
        valueType === EntitlementValueType.Numeric,
      then: () => Yup.string().required("Required"),
    }),
    valueTraitId: Yup.string().when("valueType", {
      is: (valueType: EntitlementValueType) =>
        valueType === EntitlementValueType.Trait,
      then: () => Yup.string().required("Required"),
    }),
    valueType: Yup.string().required("Required"),
    expirationDate: Yup.date().min(
      new Date(Date.now()),
      "Expiration date should be in the future.",
    ),
  });

  const onSubmit = async (
    {
      feature,
      company,
      trait,
      expirationEnabled,
      expirationDate,
      ...rest
    }: CreateCompanyOverrideFormValues,
    helpers: FormikHelpers<CreateCompanyOverrideRequestBody>,
  ) => {
    setLoading(true);

    try {
      const saveFn = override?.id
        ? (values: CreateCompanyOverrideRequestBody) =>
            updateCompanyOverride(override.id, values)
        : createCompanyOverride;

      await saveFn({
        ...rest,
        expirationDate:
          expirationEnabled && expirationDate
            ? toUTCDate(expirationDate)
            : undefined,
      });

      await queryClient.invalidateQueries();

      onClose();

      setApiError(undefined);
      helpers.setSubmitting(false);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      helpers.setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Overlay
      onClose={onClose}
      className="flex items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      <OverlayModal size="lg">
        <OverlayHeaderClose onClose={onClose} />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {({ values, setFieldValue, errors, isValid }) => (
            <Form className="py-12">
              <div className="flex-1 px-12">
                <FormHeader
                  label="Company override"
                  title={
                    <DynamicTitle
                      fromLabel={values.feature?.name ?? "Feature"}
                      from={values.feature?.name}
                      toLabel={values.company?.name ?? "Company"}
                      to={values.company?.name}
                    />
                  }
                  compact
                />

                <FormColumn>
                  <FormikAsyncSelect
                    label="Company"
                    name="company"
                    defaultOptions
                    disabled={!!company}
                    loadOptions={listCompanies}
                    loadOptionsMappers={{
                      requestFilter: feature && {
                        withoutFeatureOverrideFor: feature.id,
                      },
                    }}
                    selectedOption={
                      values.company && {
                        value: values.company,
                        label: values.company?.name || values.companyId,
                      }
                    }
                    onChange={async (option: {
                      value: CompanyResponseData;
                      label: string;
                    }) => {
                      await setFieldValue("companyId", option?.value.id);
                    }}
                  />

                  <Separator />

                  <FormikAsyncSelect
                    label="Feature"
                    name="feature"
                    defaultOptions
                    disabled={!!feature}
                    loadOptions={listFeatures}
                    loadOptionsMappers={{
                      requestFilter: company && {
                        withoutCompanyOverrideFor: company.id,
                      },
                    }}
                    selectedOption={
                      values.feature && {
                        value: values.feature,
                        label: values.feature?.name || values.featureId,
                      }
                    }
                    onChange={async (option: {
                      value: Feature;
                      label: string;
                    }) => {
                      await setFieldValue("featureId", option?.value.id);
                      if (option?.value.featureType === FeatureType.Boolean) {
                        await Promise.all([
                          setFieldValue(
                            "valueType",
                            EntitlementValueType.Boolean,
                          ),
                          setFieldValue("metricPeriod", undefined),
                          setFieldValue("valueBool", true),
                          setFieldValue("valueNumeric", undefined),
                          setFieldValue(
                            "valueType",
                            EntitlementValueType.Boolean,
                          ),
                        ]);
                      } else {
                        await Promise.all([
                          setFieldValue("valueType", defaultValueType),
                          setFieldValue("valueBool", undefined),
                          setFieldValue("valueNumeric", 0),

                          // If value type was set to boolean, set it to numeric, since boolean value type is invalid for a non-boolean feature
                          values.valueType === EntitlementValueType.Boolean &&
                            setFieldValue(
                              "valueType",
                              EntitlementValueType.Numeric,
                            ),
                        ]);
                      }
                    }}
                  />

                  {!isValid && (
                    <Alert size="xs" style="yellow">
                      {JSON.stringify(errors)}
                    </Alert>
                  )}
                </FormColumn>
              </div>

              <OverrideLimitsBlock />

              {timedOverrideFlag && <OverrideExpirationBlock />}

              {apiError && (
                <div className="pt-12 px-12">
                  <Alert size="xs" style="red">
                    <div className="flex items-center justify-center space-x-2">
                      <div className="text-base font-body">
                        <span className="font-semibold">Uh-oh!</span> {apiError}
                      </div>
                    </div>
                  </Alert>
                </div>
              )}

              {values.featureId && values.companyId && (
                <div className="px-12 pt-6">
                  <OverlayFooter>
                    <button
                      className="button button-sm button-blue"
                      type="submit"
                    >
                      Save changes
                    </button>
                  </OverlayFooter>
                </div>
              )}
            </Form>
          )}
        </Formik>
      </OverlayModal>
    </Overlay>
  );
};
