import { NotFound } from "@components/blanks/pages/NotFound";
import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { Button } from "@components/ui/Button";
import { DropdownDots } from "@components/ui/DropdownDots";
import { useContextQuery } from "@hooks/useContextQuery";
import { usePermission } from "@hooks/usePermission";
import { ClerkUserPermission } from "@models/clerkUser";
import { PlanType, PlanTypeDisplay, PlanTypeIcon } from "@models/plan";
import { PlanDeleteOverlay } from "@modules/plans/components/overlays/PlanDeleteOverlay";
import { PlanRoutePaths } from "@modules/plans/consts";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { BreadCrumbTypes } from "@ui/BreadCrumbs";
import { DataBlock, DataBlockTypes } from "@ui/DataBlocks";
import { Diamond, DiamondStyleTypes } from "@ui/Diamond";
import { Icon } from "@ui/Icon";
import { PageWrapper } from "@ui/PageWrapper";
import { ProfileDetails } from "@ui/ProfileDetails";
import { ViewPageBody } from "@ui/ViewPageBody";
import { ViewPageHeader } from "@ui/ViewPageHeader";
import { ViewPageNavigationTab } from "@ui/ViewPageNavigation";
import { timeAgo } from "@utils/date";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { PlanEditOverlay } from "./overlays/PlanEditOverlay";
import { PriceInfoBlock } from "./PriceInfoBlock";
import * as api from "../queries";
import { PlanSubview } from "../types";

type PlanViewProps = {
  type: PlanType;
};

export const PlanView = ({ type }: PlanViewProps) => {
  const { id } = useParams() as {
    id: string;
  };

  const billingFlag = useSchematicFlag("billing", { fallback: true });

  const { data: plan, isLoading } = useContextQuery({
    queryKey: [`plan`, id],
    queryFn: () => api.getPlan(id),
    retry: false,
  });
  // Number indicates tab to be opened.
  //  0 - Closed
  //  1 - Define
  //  2 - Billing
  const [editOverlay, setEditOverlay] = useState(0);
  const [deleteOverlay, setDeleteOverlay] = useState(false);
  const planEditAllowed = usePermission(ClerkUserPermission.plans_edit);

  // Overlays
  const onEdit = () => {
    setEditOverlay(1);
  };

  const onDelete = () => {
    setDeleteOverlay(true);
  };

  const onClose = () => {
    setEditOverlay(0);
    setDeleteOverlay(false);
  };

  if (isLoading) {
    return <SinglePageLoader />;
  } else if (!plan) {
    return <NotFound />;
  }

  const crumbsData: BreadCrumbTypes[] = [
    ...(type === PlanType.Plan
      ? [
          {
            name: "plans",
            active: false,
            url: `../${PlanRoutePaths.Plans}`,
          },
          {
            name: `${plan.name}`,
            active: true,
            url: `../${PlanRoutePaths.Plans}/${id}`,
          },
        ]
      : []),
    ...(type === PlanType.AddOn
      ? [
          {
            name: "add ons",
            active: false,
            url: `../${PlanRoutePaths.AddOns}`,
          },
          {
            name: `${plan.name}`,
            active: true,
            url: `../${PlanRoutePaths.AddOns}/${id}`,
          },
        ]
      : []),
  ];

  const tabsData: ViewPageNavigationTab[] = [
    {
      path: PlanSubview.Entitlements,
      label: "Entitlements",
    },
    {
      path: PlanSubview.Audience,
      label: "Audience",
    },
    ...(billingFlag
      ? [
          {
            path: PlanSubview.Billing,
            label: "Billing",
          },
        ]
      : []),
  ];

  const infoData = [
    {
      title: "Type",
      label: (
        <div className="flex flex-row items-center">
          <Icon
            className="text-3xl leading-[.8em] text-gray-500 mr-0.5"
            name={PlanTypeIcon[plan.planType]}
          />
          <span className="text-base">{PlanTypeDisplay[plan.planType]}</span>
        </div>
      ),
      className: "hidden md:block",
    },
    ...(billingFlag && plan.billingProduct
      ? [
          {
            title: "Price",
            label: (
              <PriceInfoBlock
                planId={plan.id}
                items={plan.billingProduct.prices}
              />
            ),
            className: "hidden md:block",
          },
        ]
      : []),
    {
      title: "Companies",
      label: (
        <div className="text-black font-semibold flex items-baseline font-display">
          {plan.companyCount}
        </div>
      ),
    },
    {
      children: (
        <div className="flex space-x-2">
          <Button onClick={onEdit} disabled={!planEditAllowed}>
            Edit {type === PlanType.Plan ? "plan" : "add-on"}
          </Button>
          <DropdownDots
            size="md"
            links={[
              {
                label: `Delete ${type === PlanType.Plan ? "plan" : "add-on"}`,
                disabled: !planEditAllowed,
                onClick: onDelete,
              },
            ]}
          />
        </div>
      ),
    },
  ].filter(Boolean) as DataBlockTypes[];

  const planUpdatedAt = plan.updatedAt
    ? `Last modified ${timeAgo(plan.updatedAt)}`
    : "-";

  return (
    <PageWrapper>
      <div className="flex flex-col flex-1  h-[calc(100vh-92px)] overflow-hidden overflow-y-auto">
        <ViewPageHeader
          crumbsData={crumbsData}
          description={plan.description}
          tabsData={tabsData}
        >
          <ProfileDetails
            image={<Diamond style={plan.icon as DiamondStyleTypes} />}
            title={plan.name}
            label={planUpdatedAt}
          />
          <DataBlock data={infoData} />
        </ViewPageHeader>
        <ViewPageBody data={{ plan, setEditOverlay }} />
      </div>
      {editOverlay && (
        <PlanEditOverlay
          onClose={onClose}
          plan={plan}
          initialStep={editOverlay - 1}
          type={plan.planType}
        />
      )}
      {deleteOverlay && (
        <PlanDeleteOverlay onClose={onClose} onDelete={onDelete} plan={plan} />
      )}
    </PageWrapper>
  );
};
