import { useContextQuery } from "@hooks/useContextQuery";
import {
  BillingProductDetailResponseData,
  ListCustomersRequest,
} from "@models/api";
import {
  countProducts,
  listProducts,
} from "@modules/integrations/queries/products";
import { AuditLogOverlay } from "@modules/settings/components/overlays/AuditLogOverlay";
import { ColumnDef } from "@tanstack/react-table";
import { ClipCopy } from "@ui/ClipCopy";
import { InfiniteTable } from "@ui/InfiniteTable";
import { SettingsBox } from "@ui/SettingsBox";
import { TableHeader } from "@ui/TableHeader";
import { formatCurrency } from "@utils/strings";
import React, { useEffect, useMemo, useState } from "react";

export const BillingProductsTable = () => {
  const [logOverlay, setLogOverlay] = useState<string | null>(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListCustomersRequest>({});
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const getImportedProductsCount = useContextQuery({
    queryKey: ["getImportedProductsCount"],
    queryFn: () => countProducts(filter),
  });

  useEffect(() => {
    setFilter((oldFilter) => ({
      ...oldFilter,
      q: searchTerm === "" ? undefined : searchTerm,
    }));
  }, [searchTerm]);

  const columns = useMemo<ColumnDef<BillingProductDetailResponseData>[]>(
    () => [
      {
        id: "name",
        header: "Name",
        accessorKey: "name",
        size: 170,
        cell: (cellInfo) => {
          const product = cellInfo.row.original;
          return (
            <span className="text-gray-400 text-sm">
              {product.name ? product.name : "N/A"}
            </span>
          );
        },
      },
      {
        id: "price",
        header: "Price",
        accessorKey: "price",
        size: 352,
        cell: (cellInfo) => {
          const product = cellInfo.row.original;
          const prices = product.prices;
          if (prices.length === 0) {
            return (
              <span className="font-semibold text-gray-400 text-sm">N/A</span>
            );
          }

          if (prices.length > 1) {
            return (
              <span className="font-semibold text-gray-400 text-sm">
                {prices.length} prices
              </span>
            );
          }

          return (
            <span className="font-semibold text-gray-400 text-sm">
              {formatCurrency(
                prices[0].price,
                prices[0].currency.toUpperCase(),
              )}{" "}
              / {prices[0].interval}
            </span>
          );
        },
      },
      {
        id: "external_id",
        header: "Stripe ID",
        accessorKey: "external_id",
        size: 190,
        cell: (cellInfo) => {
          const id = cellInfo.row.original.externalId;
          return <ClipCopy data={id} className="w-full" truncate />;
        },
      },
    ],
    [],
  );

  const importedCount = getImportedProductsCount.isSuccess
    ? getImportedProductsCount.data?.data.count
    : 0;

  return (
    <SettingsBox
      title={
        <div className="text-3xl font-normal">{`${importedCount} Products Imported`}</div>
      }
      style="boxed"
      className="h-auto !pb-8"
    >
      {importedCount !== 0 && (
        <>
          <TableHeader
            headerText={"Imported products"}
            searchPlaceholder="Search product, resource, request id, URL"
            onSearch={handleSearch}
            className="!mb-0"
          >
            {/*<ToggleButtonGroup*/}
            {/*  defaultValue={AuditLogTableRequest.App}*/}
            {/*  buttons={requestTypeOptions}*/}
            {/*  onToggleClick={setRequestTypeFilter}*/}
            {/*/>*/}
          </TableHeader>
          <InfiniteTable
            queryKey={["list-products"]}
            queryFn={listProducts}
            filter={filter}
            columns={columns}
          />
        </>
      )}

      {logOverlay && (
        <AuditLogOverlay id={logOverlay} onClose={() => setLogOverlay(null)} />
      )}
    </SettingsBox>
  );
};
