import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { BlankStateOnCreate } from "@ui/PageBlankState";

export const CompanyOverridesTableBlankState = ({
  onCreate,
  disabled,
}: BlankStateOnCreate) => {
  return (
    <Alert
      background="schematic"
      size="lg"
      style="gray"
      className="text-center"
    >
      <div className="flex flex-row justify-between w-full flex-1 relative z-10 text-gray">
        <div className="flex flex-col text-left items-start space-y-2">
          <div className="text-[25px] font-medium font-body">
            📦 Add company override
          </div>
          <div className="text-lg leading-6 text-gray-600">
            Create an override to grant access to a feature specifically for
            this company. <br />
            This override gets evaluated ahead of all other rules.
          </div>
        </div>
        <div className="flex items-center">
          {onCreate && (
            <Button color="blue" onClick={onCreate} disabled={disabled}>
              Add override
            </Button>
          )}
        </div>
      </div>
    </Alert>
  );
};
