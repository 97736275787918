import { PlanType } from "@models/plan";
import { ViewWrapper } from "@ui/ViewWrapper";

import { useState } from "react";
import { PlanEditOverlay } from "./overlays/PlanEditOverlay";
import { PlansTable } from "./tables/PlansTable";

interface PlansViewProps {
  type: PlanType;
}

export const PlansView = ({ type }: PlansViewProps) => {
  const [overlay, setOverlay] = useState(false);
  const onOverlayClose = () => setOverlay(false);
  const onCreate = () => {
    setOverlay(true);
  };

  return (
    <ViewWrapper>
      <PlansTable type={type} onCreate={onCreate} />
      {overlay && <PlanEditOverlay onClose={onOverlayClose} type={type} />}
    </ViewWrapper>
  );
};
