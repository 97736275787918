import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";

interface WebhooksBlankStateProps {
  onClick?: any;
}

export const WebhooksBlankState = ({ onClick }: WebhooksBlankStateProps) => {
  return (
    <Alert
      background="schematic"
      size="lg"
      style="gray"
      className="text-center mt-6"
    >
      <div className="flex flex-row justify-between w-full flex-1 relative z-10 text-gray">
        <div className="flex flex-col text-left items-start space-y-2">
          <div className="text-[25px] font-medium font-body">
            No webhooks created yet
          </div>
          <div className="text-lg leading-6 text-gray-600">
            Add a webhook to start receiving webhook events.
          </div>
        </div>
        {onClick && (
          <div className="flex items-center justify-center">
            <Button onClick={() => onClick()}>Create webhook</Button>
          </div>
        )}
      </div>
    </Alert>
  );
};
