import { Error } from "@ui/Error";
import cx from "classnames";
import { ErrorMessage, Field, FormikValues } from "formik";
import DateView from "react-datepicker";

export interface DatePickerProps {
  label?: string;
  name: string;
  description?: string;
  value?: any;
}

export const FormikDatePicker = ({
  label,
  name,
  description,
  ...rest
}: DatePickerProps) => {
  const wrapperStyles = cx("relative flex flex-col");

  return (
    <div className={wrapperStyles}>
      <label htmlFor={name} className="label-md">
        {label}
      </label>

      <Field id={name} name={name}>
        {({ form, field }: FormikValues) => {
          const { setFieldValue } = form;
          const { value } = field;

          const selectedDate = value ? new Date(value) : null;

          return (
            <DateView
              id={name}
              {...field}
              {...rest}
              className="w-full"
              dateFormat="MMM dd, yyyy h:mma"
              placeholderText="Select a date"
              popperPlacement="top"
              selected={selectedDate}
              onChange={(date: Date) => {
                const endOfDay = new Date(
                  new Date(date).setHours(23, 59, 59, 999),
                );
                setFieldValue(name, endOfDay);
              }}
            />
          );
        }}
      </Field>

      {description && (
        <div className="text-xs text-gray-400 mt-2">{description}</div>
      )}

      <ErrorMessage name={name} component={Error} />
    </div>
  );
};
