import { useUser } from "@clerk/clerk-react";
import { randomIcon } from "@components/icons";
import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { Button } from "@components/ui/Button";
import { LabeledTooltip } from "@components/ui/LabeledTooltip";
import { EventTypeOptionLabel } from "@components/ui/OptionLabel";
import { OverlayFormAlert } from "@components/ui/OverlayFormAlert";
import { DropdownIcons } from "@components/ui/SelectIcons";
import { EnvironmentContext } from "@contexts/EnvironmentContext";
import * as eventsApi from "@data/events";
import * as api from "@data/features";
import { errorMessage } from "@data/index";
import { FormikAsyncSelect } from "@forms/FormikAsyncSelect";
import { FormikControl } from "@forms/FormikControl";
import { FormikSelect } from "@forms/FormikSelect";
import { FormikStep, FormikStepper } from "@forms/FormikStepper";
import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { EventSummaryResponseData } from "@models/api";
import {
  Feature,
  CreateFeatureReq,
  FeatureType,
  FlagType,
} from "@models/feature";
import {
  FeatureTypeDisplay,
  FeatureTypeIcon,
  FeatureTypes,
} from "@modules/features";
import { FeatureSubview } from "@modules/features/types";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { FormColumn, FormHeader, FormRow } from "@ui/FormParts";
import { Overlay, OverlayModal } from "@ui/Overlay";
import { Select } from "@ui/Select";
import { FormikHelpers } from "formik";
import { useContext, useEffect, useState } from "react";
import slugify from "slugify";
import * as Yup from "yup";
import { FeatureOverlayTraitStep } from "./FeatureOverlayTraitStep";
import { listClerkOrganizationUsers } from "../../queries/users";

export interface FeatureOverlayProps {
  feature?: Feature;
  onClose: () => void;
}

export const FeatureOverlay = ({ feature, onClose }: FeatureOverlayProps) => {
  const { environmentId } = useContext(EnvironmentContext);
  const navigate = useNavigateEnvironment();
  const queryClient = useQueryClient();
  const verb = feature?.id ? "Edit" : "Create";
  const [eventType, setEventType] = useState<
    EventSummaryResponseData | null | ""
  >(null);
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState<CreateFeatureReq>();
  const [dirty, setDirty] = useState(false);
  const [closeAlert, setCloseAlert] = useState(false);
  const { user } = useUser();
  const [maintainer] = useState(feature?.maintainer || user);

  useEffect(() => {
    if (!feature?.eventSubtype) {
      setEventType(null);
      return;
    }

    const fetchEventType = async () => {
      const event = await eventsApi.getEventType(
        feature.eventSubtype as string,
      );
      setEventType(event);
    };

    fetchEventType();
  }, [feature]);

  const flag = (feature?.flags || [])[0];
  const initialValues = {
    description: feature?.description || "",
    eventSubtype: feature?.eventSubtype || null,
    featureType: feature?.featureType || FeatureType.Boolean,
    flag: {
      defaultValue: flag?.defaultValue || false,
      description: flag?.description || "",
      flagType: flag?.flagType || FlagType.Boolean,
      id: flag?.id,
      key: flag?.key || "",
      name: flag?.name || "",
    },
    name: feature?.name || "",
    icon: feature?.icon || randomIcon(),
    traitId: feature?.trait?.id || null,
    trait: feature?.trait || null,
    maintainerClerkId: maintainer?.id || null,
  };

  // Set flag key to slugified feature name if not already set
  const onStepChange = (
    values: CreateFeatureReq,
    helpers: FormikHelpers<CreateFeatureReq>,
  ) => {
    if (!values.flag?.key) {
      helpers.setFieldValue("flag.key", slugify(values.name, { lower: true }));
    }
  };

  const onSubmit = (
    values: CreateFeatureReq,
    helpers: FormikHelpers<CreateFeatureReq>,
    editMode?: boolean,
  ) => {
    setLoading(true);
    const saveFn = feature?.id
      ? (values: CreateFeatureReq) => api.updateFeature(feature.id, values)
      : api.createFeature;

    const onSuccessFn = feature?.id
      ? onClose
      : (createdFeature: Feature) =>
          navigate(
            `features/${createdFeature.id}/${FeatureSubview.Entitlements}`,
          );

    saveFn(values)
      .then((feature: Feature) => {
        queryClient.invalidateQueries();
        !editMode ? onSuccessFn(feature) : helpers.resetForm({ values });
        setApiError(undefined);
        helpers.setSubmitting(false);
        setLoading(false);
        onClose();
      })
      .catch((error) => {
        setApiError(errorMessage(error));
        helpers.setSubmitting(false);
        setLoading(false);
      });
  };

  const handleClose = () => (dirty ? setCloseAlert(true) : onClose());
  const isTraitBased = values && values?.featureType === "trait";
  const isEventBased = values && values?.featureType === "event";

  return (
    <Overlay onClose={handleClose}>
      {loading && <SchematicOverlayLoader />}
      {closeAlert && (
        <OverlayFormAlert setDirtyForm={setCloseAlert} onClose={onClose} />
      )}
      <OverlayModal size="xl" expand>
        <LabeledTooltip
          label="All Environments"
          description="Features exist in all environments"
          position="absolute"
          className="top-10 right-16"
          placement="bottom-center"
        />

        <FormikStepper
          className="flex-1 w-full"
          onStepChange={onStepChange}
          onSubmit={onSubmit}
          onClose={handleClose}
          innerRef={(formikActions: any) => {
            formikActions && setValues(formikActions.values);
            formikActions && setDirty(formikActions.dirty);
          }}
          dirty={dirty}
          editMode={!!feature?.id}
          initialValues={initialValues}
          expand
        >
          <FormikStep
            label="Define"
            validationSchema={Yup.object({
              name: Yup.string()
                .required("Required")
                .max(255, "Name must be at most 255 characters long")
                .min(1, "Name must be at least 1 character long"),
              description: Yup.string().max(
                1024,
                "Description must be at most 1024 characters long",
              ),
              featureType: Yup.mixed<FeatureType>()
                .oneOf(Object.values(FeatureType))
                .required("Required"),
              icon: Yup.string(),
              maintainerClerkId: Yup.string().required("Required"),
            })}
          >
            <FormHeader
              label={`${verb} feature`}
              title="Define feature"
              description="Unlock plan entitlements, company overrides, and track feature utilization."
            />

            <FormColumn>
              <FormRow>
                <DropdownIcons name="icon" label="Icon" type="icon" />

                {/* <IconsSelect name="icon" label="Icon" type="icon" /> */}

                <div className="flex-1">
                  <FormikControl
                    control="input"
                    name="name"
                    type="text"
                    label="Feature Name"
                    placeholder="Enter name"
                    description="A human-friendly name for your feature."
                  />
                </div>
              </FormRow>

              <FormikControl
                control="input"
                name="description"
                type="text"
                label="Description"
                placeholder="Enter description"
                description="Optional. What does this feature do for the user?"
              />

              <FormikControl
                control="select"
                name="featureType"
                type="text"
                label="How do you want to control access to this feature?"
                placeholder="Select feature type"
                description={
                  <>
                    <strong>{FeatureTypeDisplay[FeatureType.Boolean]}</strong>{" "}
                    features are On or Off, example: Single Sign On
                    <br />
                    <strong>
                      {FeatureTypeDisplay[FeatureType.Trait]}
                    </strong>{" "}
                    features are defined by Company or User trait, example:
                    Seats
                    <br />
                    <strong>
                      {FeatureTypeDisplay[FeatureType.Event]}
                    </strong>{" "}
                    features are defined by behavior, example: Search or Query
                  </>
                }
                options={FeatureTypes.map((featureType) => {
                  return {
                    value: featureType,
                    label: FeatureTypeDisplay[featureType],
                    icon: FeatureTypeIcon[featureType],
                  };
                })}
              />

              <FormikAsyncSelect
                defaultOptions
                label="Select maintainer user"
                loadOptions={listClerkOrganizationUsers}
                loadOptionsMappers={{
                  mapperFunction: (maintainer) => ({
                    value: maintainer.userId,
                    label: maintainer.identifier,
                  }),
                }}
                name="maintainerClerkId"
                selectedOption={
                  maintainer && {
                    value: maintainer.id,
                    label: maintainer.emailAddresses[0].emailAddress,
                  }
                }
              />
            </FormColumn>
          </FormikStep>

          <FormikStep
            label="Flag"
            validationSchema={Yup.object({
              flag: Yup.object({
                flagType: Yup.string().required("Required"),
                key: Yup.string()
                  .required("Must provide a key")
                  .matches(
                    /^[A-Za-z0-9._-]+$/,
                    "Key must contain only letters, numbers, ., _ or -",
                  )
                  .max(255, "Key must be at most 255 characters long")
                  .min(1, "Key must be at least 1 character long"),
              }),
            })}
          >
            <FormHeader
              label={`${verb} feature`}
              title="Define flag"
              description={
                <>Add the flag needed to enable this feature. See </>
              }
            />

            <FormColumn>
              <FormikControl
                control="input"
                name="flag.key"
                type="text"
                label="Flag key"
                description="Keys are case-insensitive."
              />
              <FormikSelect
                label="Flag type"
                name="flag.flagType"
                disabled
                options={[
                  {
                    value: FlagType.Boolean,
                    label: "Boolean",
                  },
                ]}
              />

              <FormRow>
                <Select
                  label="Variation 1"
                  name="variationOne"
                  disabled
                  selectedOption={{
                    value: true,
                    label: (
                      <span className="inline-flex items-center">
                        <span className="mr-2 inline-block text-xs">🟢</span>{" "}
                        True
                      </span>
                    ),
                  }}
                  options={[
                    {
                      value: true,
                      label: (
                        <span className="inline-flex items-center">
                          <span className="mr-2 inline-block text-xs">🟢</span>{" "}
                          True
                        </span>
                      ),
                    },
                    {
                      value: false,
                      label: (
                        <span className="inline-flex items-center">
                          <span className="mr-2 inline-block text-xs">🔴</span>{" "}
                          False
                        </span>
                      ),
                    },
                  ]}
                />
                <Select
                  label="Variation 2"
                  name="variationTwo"
                  disabled
                  options={[
                    {
                      value: true,
                      label: (
                        <span className="inline-flex items-center">
                          <span className="mr-2 inline-block text-xs">🟢</span>{" "}
                          True
                        </span>
                      ),
                    },
                    {
                      value: false,
                      label: (
                        <span className="inline-flex items-center">
                          <span className="mr-2 inline-block text-xs">🔴</span>{" "}
                          False
                        </span>
                      ),
                    },
                  ]}
                  selectedOption={{
                    value: false,
                    label: (
                      <span className="inline-flex items-center">
                        <span className="mr-2 inline-block text-xs">🔴</span>{" "}
                        False
                      </span>
                    ),
                  }}
                />
              </FormRow>
            </FormColumn>
          </FormikStep>

          {isTraitBased && (
            <FormikStep
              label="Trait"
              validationSchema={Yup.object().shape({
                featureType: Yup.mixed<FeatureType>().oneOf(
                  Object.values(FeatureType),
                ),
                traitId: Yup.string()
                  .nullable()
                  .when("featureType", {
                    is: FeatureType.Trait,
                    then: () => Yup.string().required("Required"),
                  }),
              })}
            >
              <FeatureOverlayTraitStep verb={verb} feature={feature} />
            </FormikStep>
          )}

          <FormikStep
            label="Event"
            validationSchema={Yup.object({
              featureType: Yup.mixed<FeatureType>().oneOf(
                Object.values(FeatureType),
              ),
              eventSubtype: Yup.string()
                .nullable()
                .when("featureType", {
                  is: FeatureType.Event,
                  then: () => Yup.string().required("Required"),
                }),
            })}
          >
            <FormHeader
              label={`${verb} feature`}
              title="Assign event"
              description={<>Add the event that track this feature’s usage. </>}
            />

            <FormColumn>
              {eventType === null || feature?.eventSubtype ? (
                <FormikAsyncSelect
                  defaultOptions
                  label="Select event type"
                  loadOptions={eventsApi.listEventTypes}
                  loadOptionsMappers={{
                    mapperFunction: (eventType) => ({
                      value: eventType.eventSubtype,
                      label: <EventTypeOptionLabel eventType={eventType} />,
                    }),
                  }}
                  placeholder="Start typing to add one..."
                  name="eventSubtype"
                  description={isEventBased ? null : "Optional field"}
                  nullable
                  onCreate={(option: string) => {
                    const event = option;
                    const createdEventType = {
                      companyCount: 0,
                      environmentId,
                      eventCount: 0,
                      eventSubtype: event,
                      userCount: 0,
                    };

                    return {
                      value: event,
                      label: (
                        <EventTypeOptionLabel
                          eventType={createdEventType as any}
                        />
                      ),
                    };
                  }}
                  creatable
                  creatableLabel="Event"
                  selectedOption={
                    eventType && {
                      value: eventType.eventSubtype,
                      label: <EventTypeOptionLabel eventType={eventType} />,
                    }
                  }
                />
              ) : (
                <Alert style="gray" size="sm" className="mb-12">
                  <div className="flex justify-between w-full flex-1 relative z-10 text-gra">
                    <div className="text-base font-medium  leading-6">
                      Add an event to track and determine
                      <br />
                      access based on usage
                    </div>
                    <div>
                      <Button
                        color="blue"
                        size="md"
                        onClick={() => setEventType("")}
                      >
                        Add event
                      </Button>
                    </div>
                  </div>
                </Alert>
              )}

              {apiError && (
                <div className="px-2">
                  <Alert size="xs" style="red">
                    <div className="flex items-center justify-center space-x-2">
                      <div className="text-base font-body ">
                        <span className="font-semibold">Uh-oh!</span> {apiError}
                      </div>
                    </div>
                  </Alert>
                </div>
              )}
            </FormColumn>
          </FormikStep>
        </FormikStepper>
      </OverlayModal>
    </Overlay>
  );
};
