import { Tabs } from "@components/ui/Tabs";
import { useEditor } from "@craftjs/core";
import {
  useEmbed,
  type EmbedContextProps,
} from "@schematichq/schematic-components";
import { createElement, useMemo, useState } from "react";
import FontPicker from "react-fontpicker-ts";
import { ComponentsList } from "./ComponentsList";
import * as Settings from "./Settings";
import { Checkbox, Color, Columns, Select, TextInput, Toggle } from "../../ui";

type TypographyKey = keyof EmbedContextProps["settings"]["theme"]["typography"];

const typographyOptions: { value: TypographyKey; label: string }[] = [
  {
    value: "heading1",
    label: "Heading 1",
  },
  {
    value: "heading2",
    label: "Heading 2",
  },
  {
    value: "heading3",
    label: "Heading 3",
  },
  {
    value: "heading4",
    label: "Heading 4",
  },
  {
    value: "heading5",
    label: "Heading 5",
  },
  {
    value: "heading6",
    label: "Heading 6",
  },
  { value: "text", label: "Text" },
  { value: "link", label: "Link" },
];

export const Sidebar = () => {
  const [selectedTab, setSelectedTab] = useState("elements");
  const [selectedTypography, setSelectedTypography] =
    useState<TypographyKey>("heading1");

  const { layout } = useEmbed();

  const { selected, settings } = useEditor((state, query) => {
    const [currentNodeId] = state.events.selected;

    let updated;
    if (currentNodeId) {
      updated = {
        id: currentNodeId,
        name: state.nodes[currentNodeId].data.name,
        settings:
          state.nodes[currentNodeId].related &&
          state.nodes[currentNodeId].related.settings,
        isDeletable: query.node(currentNodeId).isDeletable(),
      };
    }

    return {
      selected: updated,
      settings: state.nodes.ROOT.data.props
        .settings as EmbedContextProps["settings"],
    };
  });

  const { actions } = useEditor((state) => {
    const [currentNodeId] = state.events.selected;
    if (
      layout === "portal" &&
      currentNodeId !== "ROOT" &&
      selected?.id !== currentNodeId
    ) {
      setSelectedTab("elements");
    } else if (layout !== "portal") {
      setSelectedTab("design");
    }
  });

  const tabData = useMemo(() => {
    const items = [
      {
        key: "design",
        name: "Design",
        content: (
          <Settings.Root
            description="Define styling for all elements within this component."
            bordered={false}
          >
            <Settings.Section>
              <Settings.Header>General</Settings.Header>
              <Settings.Input label="Columns">
                <Columns />
              </Settings.Input>
              <Settings.Input label="Sections">
                <Toggle
                  value={settings.theme.sectionLayout}
                  onChange={(value) => {
                    actions.setProp("ROOT", (props: EmbedContextProps) => {
                      if (value !== "merged" && value !== "separate") return;
                      props.settings.theme.sectionLayout = value;
                    });
                  }}
                  options={[
                    {
                      label: "Merged",
                      value: "merged",
                    },
                    {
                      label: "Separate",
                      value: "separate",
                    },
                  ]}
                />
              </Settings.Input>
              <Settings.Input label="Primary Color">
                <Color
                  value={settings.theme.primary}
                  onChange={(value) => {
                    actions.setProp("ROOT", (props: EmbedContextProps) => {
                      props.settings.theme.primary = value;
                    });
                  }}
                />
              </Settings.Input>
              <Settings.Input label="Secondary Color">
                <Color
                  value={settings.theme.secondary}
                  onChange={(value) => {
                    actions.setProp("ROOT", (props: EmbedContextProps) => {
                      props.settings.theme.secondary = value;
                    });
                  }}
                />
              </Settings.Input>
            </Settings.Section>

            <Settings.Section>
              <Settings.Header>Card Styling</Settings.Header>

              <Settings.Input label="Background Color">
                <Color
                  value={settings.theme.card.background}
                  onChange={(value) => {
                    actions.setProp("ROOT", (props: EmbedContextProps) => {
                      props.settings.theme.card.background = value;
                    });
                  }}
                />
              </Settings.Input>
              <Settings.Input label="Border Radius">
                <TextInput
                  suffix="px"
                  defaultValue={settings.theme.card.borderRadius}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const updated = parseInt(event.target.value);
                    if (isNaN(updated)) return;
                    actions.setProp("ROOT", (props: EmbedContextProps) => {
                      props.settings.theme.card.borderRadius = updated;
                    });
                  }}
                />
              </Settings.Input>
              <Settings.Input label="Card Shadow">
                <Checkbox
                  checked={settings.theme.card.hasShadow}
                  onChange={() => {
                    actions.setProp("ROOT", (props: EmbedContextProps) => {
                      const updated = !props.settings.theme.card.hasShadow;
                      props.settings.theme.card.hasShadow = updated;
                    });
                  }}
                />
              </Settings.Input>
              <Settings.Input label="Card Padding">
                <TextInput
                  suffix="px"
                  value={settings.theme.card.padding}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const updated = parseInt(event.target.value);
                    if (isNaN(updated)) return;
                    actions.setProp("ROOT", (props: EmbedContextProps) => {
                      props.settings.theme.card.padding = updated;
                    });
                  }}
                />
              </Settings.Input>
            </Settings.Section>

            <Settings.Section>
              <Settings.Header>Tyopgraphy</Settings.Header>
              <Select
                className="h-10"
                name="typography-menu"
                placeholder="Select an option"
                options={typographyOptions}
                value={typographyOptions.find(
                  (opt) => opt.value === selectedTypography,
                )}
                onChange={(option) => {
                  option && setSelectedTypography(option.value);
                }}
                isMulti={false}
              />
              <Settings.Input label="Font">
                <FontPicker
                  defaultValue={
                    settings.theme.typography[selectedTypography].fontFamily
                  }
                  value={(font: string) => {
                    actions.setProp("ROOT", (prop) => {
                      prop.settings.theme.typography[
                        selectedTypography
                      ].fontFamily = font;
                    });
                  }}
                />
              </Settings.Input>
              <Settings.Input label="Font Size">
                <TextInput
                  suffix="px"
                  value={settings.theme.typography[selectedTypography].fontSize}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    actions.setProp("ROOT", (props: EmbedContextProps) => {
                      const updated = parseInt(event.target.value);
                      if (isNaN(updated)) return;
                      props.settings.theme.typography[
                        selectedTypography
                      ].fontSize = updated;
                    });
                  }}
                />
              </Settings.Input>
              <Settings.Input label="Font Color">
                <Color
                  value={settings.theme.typography[selectedTypography].color}
                  onChange={(value) => {
                    actions.setProp("ROOT", (props: EmbedContextProps) => {
                      props.settings.theme.typography[
                        selectedTypography
                      ].color = value;
                    });
                  }}
                />
              </Settings.Input>
              <Settings.Input label="Font Weight">
                <Select
                  className="w-28 h-[1.875rem]"
                  options={new Array(7).fill(0).map((_, i) => {
                    const n = (i + 2) * 100;
                    return {
                      label: n.toString(),
                      value: n,
                    };
                  })}
                  defaultValue={{
                    value:
                      settings.theme.typography[selectedTypography].fontWeight,
                    label:
                      settings.theme.typography[
                        selectedTypography
                      ].fontWeight.toString(),
                  }}
                  rawValue={settings.theme.typography[selectedTypography]}
                  onChange={(option) => {
                    option &&
                      actions.setProp("ROOT", (prop) => {
                        prop.settings.theme.typography[
                          selectedTypography
                        ].fontWeight = option.value;
                      });
                  }}
                  isMulti={false}
                />
              </Settings.Input>
            </Settings.Section>

            {settings.badge && (
              <Settings.Section>
                <Settings.Header>Badge Styling</Settings.Header>
                <Settings.Input label="Alignment">
                  <Toggle
                    value={settings.badge.alignment}
                    onChange={(value) => {
                      actions.setProp("ROOT", (props: EmbedContextProps) => {
                        if (
                          value !== "start" &&
                          value !== "center" &&
                          value !== "end"
                        )
                          return;
                        props.settings.badge.alignment = value;
                      });
                    }}
                    options={[
                      {
                        label: "Left",
                        value: "start",
                      },
                      {
                        label: "Center",
                        value: "center",
                      },
                      {
                        label: "Right",
                        value: "end",
                      },
                    ]}
                  />
                </Settings.Input>
              </Settings.Section>
            )}
          </Settings.Root>
        ),
      },
    ];

    if (layout === "portal") {
      items.unshift({
        key: "elements",
        name: "Elements",
        content: selected?.settings ? (
          createElement(selected.settings)
        ) : (
          <ComponentsList />
        ),
      });
    }

    return items;
  }, [actions, layout, settings, selectedTypography, selected?.settings]);

  return (
    <div className="grow min-w-[21.5rem] max-w-[21.5rem] bg-white border-l border-gray-300 drop-shadow-lg overflow-hidden overflow-y-auto">
      <Tabs
        style="compact"
        className="mt-6"
        data={tabData}
        value={selectedTab}
        onValueChange={(value) => {
          setSelectedTab(value);
        }}
      />
    </div>
  );
};
