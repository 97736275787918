import { TableLoader } from "@components/loaders/TableLoader";
import { Alert } from "@components/ui/Alert";
import { ClipCopy } from "@components/ui/ClipCopy";
import { DropdownDots } from "@components/ui/DropdownDots";
import { Pill } from "@components/ui/Pill";
import * as api from "@data/features";
import * as flagsApi from "@data/flags";
import { usePermission } from "@hooks/usePermission";
import useSecondaryTableHeader from "@hooks/useSecondaryTableHeader";
import useTablePagination from "@hooks/useTablePagination";
import { ListFeaturesParams } from "@models/api";
import { ClerkUserPermission } from "@models/clerkUser";
import { Feature, FeatureType } from "@models/feature";
import { FeatureTypeCell } from "@modules/features/components/FeatureTypeCell";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useQueryClient } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import { ButtonProps } from "@ui/Button";
import { IconRound } from "@ui/Icon";
import { IconNameTypes } from "@ui/Icon/consts";
import { PillGroup } from "@ui/PillGroup";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import { formatDate, timeAgo } from "@utils/date";

import pluralize from "pluralize";
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { useNavigate, useParams } from "react-router-dom";

import { FeaturesBlankState } from "../blank-states/FeaturesBlankState";
import { FeatureDeleteOverlay } from "../overlays/FeatureDeleteOverlay";

export const FeaturesTableEmptyFilterState = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-[30vh] bg-zinc-100 rounded">
      <p className="text-4xl font-bold">No results.</p>
      <p className="text-sm mt-6">Try a different search.</p>
    </div>
  );
};

export interface FeaturesTableProps {
  onCreate: () => void;
}

export const FeaturesTable = ({ onCreate }: FeaturesTableProps) => {
  const { environmentId } = useParams() as {
    environmentId: string;
  };
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const featureEditAllowed = usePermission(ClerkUserPermission.features_edit);
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteFeature, setDeleteFeature] = useState<Feature | undefined>();
  const [successAlert, setSuccessAlert] = useState("");
  const [filter, setFilter] = useState<ListFeaturesParams>({});

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter(searchTerm === "" ? {} : { q: searchTerm });
  }, [searchTerm]);

  const columns = useMemo<ColumnDef<Feature>[]>(() => {
    return [
      {
        id: "feature",
        header: "Feature",
        accessorKey: "feature",
        cell: (cellInfo) => {
          const cell = cellInfo.row.original;
          return (
            <div className="flex flex-row items-center">
              <IconRound name={cell.icon as IconNameTypes} size="sm" />
              <div className="flex flex-col ml-3 space-y-1">
                <div className="leading-none font-medium">{cell.name}</div>
                <div className="leading-none text-gray-400">{cell.id}</div>
              </div>
            </div>
          );
        },
      },
      {
        id: "type",
        header: "Type",
        accessorKey: "type",
        size: 150,
        cell: (cellInfo) => {
          const cell = cellInfo.row.original;
          return (
            <FeatureTypeCell featureType={cell.featureType as FeatureType} />
          );
        },
      },
      {
        id: "plans",
        header: "Plans",
        accessorKey: "plans",
        cell: (cellInfo) => {
          const feature = cellInfo.row.original;

          return (
            <div className="flex flex-row items-center">
              <PillGroup items={feature.plans} limit={1} />
            </div>
          );
        },
      },
      {
        id: "last60Usage",
        header: "Last 60 Usage",
        accessorKey: "last60Usage",
        size: 160,
        cell: (cellInfo) => {
          const feature = cellInfo.row.original;

          return (
            <div className="flex flex-row items-center group">
              {feature.eventSubtype ? (
                (feature.eventSummary?.eventCount || 0).toString()
              ) : (
                <div
                  className="relative inline-block"
                  onClick={(e: MouseEvent<HTMLDivElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(`${feature.id}/usage`);
                  }}
                >
                  <span className="group-hover:text-blue-400 text-gray-400">
                    n/a
                  </span>
                  <div className="z-[5000] bottom-full pb-2 group-hover:opacity-100 group-hover:visible opacity-0 invisible tooltip-table-hack absolute left-[50%] translate-x-[-50%]">
                    <div className="min-w-fit bg-white shadow-lg bottom-full rounded-md flex flex-col space-y-2 z-20 p-5">
                      <div className="text-center">
                        <span className="text-blue-400 break-words hover:underline">
                          Assign an event
                        </span>{" "}
                        to this feature to start
                        <br />
                        tracking usage.
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          );
        },
      },
      {
        id: "flagKey",
        header: "Flag",
        accessorKey: "flagKey",
        cell: (cellInfo) => {
          const flag = cellInfo.row.original.flags[0];
          return (
            <div className="flex flex-row items-center group">
              {flag ? (
                <Pill
                  color="gray"
                  type="tag"
                  text="code"
                  className="lowercase "
                >
                  <ClipCopy data={flag.key} className="leading-[1rem]" />
                </Pill>
              ) : (
                <span className="text-gray-400 text-base">n/a</span>
              )}
            </div>
          );
        },
      },
      {
        id: "lastModified",
        header: "Last Modified",
        accessorKey: "lastModified",
        cell: (cellInfo) => {
          const feature = cellInfo.row.original;
          return (
            <div className="inline-block space-y-1">
              <div className="leading-none font-bold">
                {timeAgo(feature.updatedAt)}
              </div>
              <div className="leading-none text-gray-400">
                Created {formatDate(feature.createdAt)}
              </div>
            </div>
          );
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: (cellInfo) => {
          const flag = cellInfo.row.original;
          return (
            <div className="flex flex-row items-end justify-end relative z-10">
              <DropdownDots
                color="gray"
                links={[
                  {
                    label: "Delete feature",
                    disabled: !featureEditAllowed,
                    onClick: () => {
                      setSuccessAlert("");
                      setDeleteFeature(flag);
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }, [navigate]);

  const getHeaderText = (count: number) => {
    return pluralize("Feature", count, true);
  };

  const {
    countQuery,
    headerText,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<Feature, ListFeaturesParams>(
    ["features"],
    api.listFeatures,
    api.countFeatures,
    filter,
    getHeaderText,
  );

  const secondaryHeaderText = useSecondaryTableHeader(
    "flags",
    flagsApi.countFeatureFlags,
  );

  const detailsLink = (row: Feature) => {
    return `features/${row.id}`;
  };

  const onDelete = useCallback(() => {
    queryClient.invalidateQueries();

    setSuccessAlert("Feature deleted successfully");
    setDeleteFeature(undefined);
  }, [queryClient]);

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const headerButtons: ButtonProps[] = [
    {
      children: <>Create</>,
      color: "blue",
      disabled:
        !useSchematicFlag("features", { fallback: true }) ||
        !featureEditAllowed,
      onClick: onCreate,
    },
  ];

  const noFeaturesCreated = countQuery?.data?.count === 0 && searchTerm === "";

  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderFeatureTable = () => {
    switch (true) {
      case noFeaturesCreated:
        return (
          <FeaturesBlankState
            onCreate={onCreate}
            disabled={!featureEditAllowed}
          />
        );
      case loading:
        return <TableLoader />;
      case listQuery.data?.length === 0:
        return <FeaturesTableEmptyFilterState />;
      default:
        return (
          listQuery?.data && (
            <Table
              columns={columns}
              data={listQuery.data}
              detailsLink={detailsLink}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
            />
          )
        );
    }
  };

  return (
    <div className="pb-16">
      {successAlert && (
        <div className="mb-4">
          <Alert style="green" size="xs" className="flex">
            {successAlert}
          </Alert>
        </div>
      )}
      {!noFeaturesCreated && (
        <TableHeader
          headerTabs={[
            {
              active: true,
              label: headerText,
              url: `/${environmentId}/features`,
            },
            {
              label: secondaryHeaderText,
              url: `/${environmentId}/flags`,
            },
          ]}
          searchPlaceholder="Search features"
          onSearch={handleSearch}
          buttons={headerButtons}
        />
      )}
      {renderFeatureTable()}

      {deleteFeature &&
        createPortal(
          <FeatureDeleteOverlay
            feature={deleteFeature}
            onClose={() => setDeleteFeature(undefined)}
            onDelete={onDelete}
          />,
          document.body,
        )}
    </div>
  );
};
